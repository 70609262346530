import React from 'react'
import sprites from '../../media/sprites.svg'

const Card = (props) => {
  const { orange, orangeCardNode, green, greenCardNode, blue, blueCardNode, orangeBackButtonNode, greenBackButtonNode, blueBackButtonNode } = props

  return (
    <div className="card_wrapper">
      <div className="card_container mw-1200">
        <div className="card card-1">
          <div className={`card__side card__side--front ${orange[0]}`}>
            <div className="card__picture card__picture--1"></div>
            <h4 className="card__heading">
              <span className="card__heading-span card__heading-span--1">Chair Vermillion</span>
            </h4>
            <div className="card__details">
              <ul className="card__details-1">
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>
                  <button className="btn btn-orange btn-medium" ref={orangeCardNode}>
                    <svg className="svg-btn svg-white">
                      <use href={`${sprites}#icon-arrow-with-circle-right`}></use>
                    </svg>
                    Read More
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card__side card__side--back card__side--back-1 ${orange[1]}`}>
            <div className="card__cta">
              <div className="card__price-box">
                <p className="card__price-only">Only</p>
                <p className="card__price-value">$297</p>
              </div>
              <a href="#solar" className="someText">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, minus?
              </a>
            </div>

            <div className="card__back-button">
              <button className="btn btn-white color-orange btn-small" ref={orangeBackButtonNode}>
                <svg className="svg-btn svg-orange">
                  <use href={`${sprites}#icon-arrow-with-circle-left`}></use>
                </svg>
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="card card-2">
          <div className={`card__side card__side--front ${green[0]}`}>
            <div className="card__picture card__picture--2"></div>
            <h4 className="card__heading">
              <span className="card__heading-span card__heading-span--2">Chair Vermillion</span>
            </h4>
            <div className="card__details">
              <ul className="card__details-2">
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>
                  <button className="btn btn-green btn-medium" ref={greenCardNode}>
                    <svg className="svg-btn svg-white">
                      <use href={`${sprites}#icon-arrow-with-circle-right`}></use>
                    </svg>
                    Read More
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card__side card__side--back card__side--back-2 ${green[1]}`}>
            <div className="card__cta">
              <div className="card__price-box">
                <p className="card__price-only">Only</p>
                <p className="card__price-value">$227</p>
              </div>
              <a href="#solar" className="">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, minus?
              </a>
            </div>
            <div className="card__back-button">
              <button className="btn btn-white color-green btn-small" ref={greenBackButtonNode}>
                <svg className="svg-btn svg-green">
                  <use href={`${sprites}#icon-arrow-with-circle-left`}></use>
                </svg>
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="card card-3">
          <div className={`card__side card__side--front ${blue[0]}`}>
            <div className="card__picture card__picture--3"></div>
            <h4 className="card__heading">
              <span className="card__heading-span card__heading-span--3">Chair Vermillion</span>
            </h4>
            <div className="card__details">
              <ul className="card__details-3">
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>Lorem Ipsum Solar</li>
                <li>
                  <button className="btn btn-blue btn-medium" ref={blueCardNode}>
                    <svg className="svg-btn svg-white">
                      <use href={`${sprites}#icon-arrow-with-circle-right`}></use>
                    </svg>
                    Read More
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card__side card__side--back card__side--back-3 ${blue[1]}`}>
            <div className="card__cta">
              <div className="card__price-box">
                <p className="card__price-only">Only</p>
                <p className="card__price-value">$439</p>
              </div>
              <a href="#solar" className="">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, minus?
              </a>
            </div>
            <div className="card__back-button">
              <button className="btn btn-white color-blue btn-small" ref={blueBackButtonNode}>
                <svg className="svg-btn svg-blue">
                  <use href={`${sprites}#icon-arrow-with-circle-left`}></use>
                </svg>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
