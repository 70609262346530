import React from 'react'

import grid_wine from '../../../media/grid_media/grid_wine.jpg'
import grid_chair from '../../../media/grid_media/grid_chair.jpg'
import grid_bedroom from '../../../media/grid_media/grid_bedroom.jpg'
import grid_living from '../../../media/grid_media/grid_living.jpg'
import grid_dining from '../../../media/grid_media/grid_dining.jpg'
import grid_dinettes from '../../../media/grid_media/grid_dinettes.jpg'
import grid_occasional from '../../../media/grid_media/grid_occasional.jpg'
import grid_bed from '../../../media/grid_media/grid_bed.jpg'
import grid_futon from '../../../media/grid_media/grid_futon.jpg'
import grid_bunkbed from '../../../media/grid_media/grid_bunkbed.jpg'
import grid_accents from '../../../media/grid_media/grid_accents.jpg'

import CategoryItem from './CategoryItem'

const Categories = () => {
  return (
    <div className="category--color">
      <div className="mw-1200">
        <div className="category--container">
          <h2 className="category--title">Categories</h2>
          <div className="category">
            <CategoryItem grid_photo={grid_wine} grid_title="Wine Stands" />
            <CategoryItem grid_photo={grid_chair} grid_title="Accent Chairs" />
            <CategoryItem grid_photo={grid_bedroom} grid_title="Bedrooms" />
            <CategoryItem grid_photo={grid_living} grid_title="Living" />
            <CategoryItem grid_photo={grid_dining} grid_title="Dining" />
            <CategoryItem grid_photo={grid_dinettes} grid_title="Dinettes" />
            <CategoryItem grid_photo={grid_occasional} grid_title="Occasional" />
            <CategoryItem grid_photo={grid_bed} grid_title="Beds" />
            <CategoryItem grid_photo={grid_futon} grid_title="Futons" />
            <CategoryItem grid_photo={grid_bunkbed} grid_title="Bunk Beds" />
            <CategoryItem grid_photo={grid_accents} grid_title="Accents" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
