import React, { useState, useEffect } from 'react'
import sprites from '../../media/sprites.svg'

import sol0 from '../../media/carouselpic/sol0.jpg'
import sol1 from '../../media/carouselpic/sol1.jpg'
import sol2 from '../../media/carouselpic/sol2.jpg'
import sol3 from '../../media/carouselpic/sol3.jpg'
import sol4 from '../../media/carouselpic/sol4.jpg'
import sol5 from '../../media/carouselpic/sol5.jpg'
import sol6 from '../../media/carouselpic/sol6.jpg'
import sol7 from '../../media/carouselpic/sol7.jpg'
import sol8 from '../../media/carouselpic/sol8.jpg'

const Carousel = () => {
  const [current, setCurrent] = useState(0)
  const [target, setTarget] = useState(0)
  const [sake, setSake] = useState(0)

  const [pictureArray] = useState(new Array(9))
  const [direction, setDirection] = useState('')
  const [button, setButton] = useState(false)
  const [afterEffectLeft, setAfterEffectLeft] = useState('')
  const [afterEffectRight, setAfterEffectRight] = useState('')

  const [circleArray, setCircleArray] = useState(
    [...pictureArray].map((element) => {
      return {}
    })
  )

  const [style, setStyle] = useState(() => {
    const myObject = {}
    const temp = [...pictureArray]
    temp.map((element, index) => {
      myObject[`item${index}`] = {}
      return index
    })

    myObject[`item0`] = { left: '0' }
    return myObject
  })

  const [newStyle] = useState(() => {
    let ggX = {}
    Object.keys(style).forEach((element) => {
      ggX[element] = { opacity: 0 }
    })
    return ggX
  })

  useEffect(() => {
    if (direction === 'right') {
      setStyle({
        ...newStyle,
        [`item${current}`]: { left: '0%' },
        [`item${target}`]: { left: '100%', transition: 'none' },
      })

      setTimeout(() => {
        setStyle({
          ...newStyle,
          [`item${current}`]: { left: '-100%' },
          [`item${target}`]: { left: '0%' },
        })
      }, 20)

      setCurrent(target)
    } else if (direction === 'left') {
      setStyle({
        ...newStyle,
        [`item${current}`]: { left: '0%' },
        [`item${target}`]: { left: '-100%', transition: 'none' },
      })

      setTimeout(() => {
        setStyle({
          ...newStyle,
          [`item${current}`]: { left: '100%' },
          [`item${target}`]: { left: '0%' },
        })
      }, 20)

      setCurrent(target)
    }

    setCircleArray(circleArray.map((element, index) => (index === target ? { backgroundColor: 'navy', fill: 'white' } : {})))
    // eslint-disable-next-line
  }, [sake])

  const circleClicked = (number) => {
    if (button === false) {
      if (number < current) {
        setTarget(number)
        goingLeft()
      } else if (number > current) {
        setTarget(number)
        goingRight()
      }
    }
  }

  const goingLeft = () => {
    setDirection('left')
    setAfterEffectLeft('after_effect')
    setSake(sake - 1)
    conditional()
  }

  const goingRight = () => {
    setDirection('right')
    setAfterEffectRight('after_effect')
    setSake(sake + 1)
    conditional()
  }

  const goLeft = () => {
    if (button === false) {
      setTarget(decrementMin(target))
      goingLeft()
    }
  }
  const goRight = () => {
    if (button === false) {
      setTarget(incrementMax(target))
      goingRight()
    }
  }

  const conditional = () => {
    setButton(true)
    setTimeout(() => {
      setAfterEffectLeft('')
      setAfterEffectRight('')
      setButton(false)
    }, 950)
  }

  const incrementMax = (number) => {
    return number === pictureArray.length - 1 ? 0 : number + 1
  }

  const decrementMin = (number) => {
    return number === 0 ? pictureArray.length - 1 : number - 1
  }

  useEffect(() => {
    const dan = setInterval(() => {
      setTarget(incrementMax(target))
      goingRight()
    }, 4000)

    return () => {
      clearInterval(dan)
    }

    // eslint-disable-next-line
  }, [current])

  return (
    <div className="showcase">
      <div className="showcase--container mw-1200">
        <div className="showcase--img_grid">
          <div className={`showcase--img_mover`}>
            <img className="showcase--img" src={sol0} alt="Bedroom set" style={style.item0} />
            <img className="showcase--img" src={sol1} alt="Bedroom set" style={style.item1} />
            <img className="showcase--img" src={sol2} alt="Bedroom set" style={style.item2} />
            <img className="showcase--img" src={sol3} alt="Bedroom set" style={style.item3} />
            <img className="showcase--img" src={sol4} alt="Bedroom set" style={style.item4} />
            <img className="showcase--img" src={sol5} alt="Bedroom set" style={style.item5} />
            <img className="showcase--img" src={sol6} alt="Bedroom set" style={style.item6} />
            <img className="showcase--img" src={sol7} alt="Bedroom set" style={style.item7} />
            <img className="showcase--img" src={sol8} alt="Bedroom set" style={style.item8} />
          </div>
          <div className="showcase--circle_group">
            {circleArray.map((element, index) => (
              <svg className="showcase--circle_icon" key={index} onClick={() => circleClicked(index)} style={element}>
                <use href={`${sprites}#icon-radio-unchecked`}></use>
              </svg>
            ))}
          </div>
        </div>

        <div className={`showcase--icon_div_left ${afterEffectLeft}`}>
          <svg className="showcase--icon_left" onClick={goLeft}>
            <use href={`${sprites}#icon-circle-left`}></use>
          </svg>
        </div>
        <div className={`showcase--icon_div_right ${afterEffectRight}`}>
          <svg className="showcase--icon_right" onClick={goRight}>
            <use href={`${sprites}#icon-circle-right`}></use>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Carousel
