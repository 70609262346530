import React from 'react'

const CategoryItem = (props) => {
  const { grid_photo, grid_title } = props

  return (
    <div className="category--item">
      <img src={grid_photo} alt="" className="category--image" />
      <h2 className="category--item_title">{grid_title}</h2>
    </div>
  )
}

export default CategoryItem
