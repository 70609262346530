import React, { Fragment } from 'react'
import sprites from '../../media/sprites.svg'

const Navbar = (props) => {
  const { categoryNode, category } = props

  return (
    <Fragment>
      <div className="navigation">
        <nav className="navigation--container mw-1200">
          <h2 className="navigation--logo">meubles sri durga</h2>
          <ul className="navigation--list">
            <li className="navigation--item">
              <a href="Home" className="navigation--link">
                Home
              </a>
            </li>
            <li className="navigation--item navigation--category_container" onClick={(e) => e.preventDefault()} ref={categoryNode}>
              <a href="Categories" className="navigation--link">
                Categories
                <svg className="navigation--category_icon">
                  <use href={`${sprites}#icon-chevron-with-circle-down`}></use>
                </svg>
              </a>

              <ul className={`navigation--category_div ${category}`}>
                <li className="navigation--category_item">
                  <span>Wine Stands</span>
                </li>
                <li className="navigation--category_item">
                  <span>Accent Chairs</span>
                </li>
                <li className="navigation--category_item">
                  <span>Bedrooms</span>
                </li>
                <li className="navigation--category_item">
                  <span>Living</span>
                </li>
                <li className="navigation--category_item">
                  <span>Dining</span>
                </li>
                <li className="navigation--category_item">
                  <span>Dinettes</span>
                </li>
                <li className="navigation--category_item">
                  <span>Occasional</span>
                </li>
                <li className="navigation--category_item">
                  <span>Beds</span>
                </li>
                <li className="navigation--category_item">
                  <span>Futons</span>
                </li>
                <li className="navigation--category_item">
                  <span>Bunk Beds</span>
                </li>
                <li className="navigation--category_item">
                  <span>Accents</span>
                </li>
              </ul>
            </li>

            <li className="navigation--item">
              <a href="Location" className="navigation--link">
                Location
              </a>
            </li>
            <li className="navigation--item">
              <a href="Contact" className="navigation--link">
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="navigation--fix"></div>
    </Fragment>
  )
}

export default Navbar
