import React from 'react'

const Location = () => {
  return (
    <div className="map">
      <div className="map--container mw-1200">
        <iframe
          className="map--iframe"
          style={{ border: '0' }}
          title="map"
          samesite="true"
          src="https://www.google.com/maps/embed/v1/place?q=Meubles%20Sri%20Durga&key=AIzaSyBhlXObZdOs6-K0x1ANdeY53s4CS2_-sjU&zoom=14"
        ></iframe>
      </div>
    </div>
  )
}

export default Location
