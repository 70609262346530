import React from 'react'
import solar from '../../media/sprites.svg'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer--container mw-1200">
        <div className="footer--information">
          <ul className="footer--list">
            <h2 className="footer--hour_title">Store Hours</h2>
            <li className="footer--item">
              <span>Monday:</span> 11:00 AM - 06:00 PM
            </li>
            <li className="footer--item">
              <span>Tuesday:</span> 11:00 AM - 06:00 PM
            </li>
            <li className="footer--item">
              <span>Wednesday:</span> 11:00 AM - 06:00 PM
            </li>
            <li className="footer--item">
              <span>Thursday:</span> 11:00 AM - 06:00 PM
            </li>
            <li className="footer--item">
              <span>Friday:</span> 11:00 AM - 08:00 PM
            </li>
            <li className="footer--item">
              <span>Saturday:</span> 12:00 PM - 05:00 PM
            </li>
            <li className="footer--item">
              <span>Sunday:</span> 12:00 PM - 05:00 PM
            </li>
          </ul>

          <ul className="footer--address">
            <h2 className="footer--contact_title">Contact Us</h2>
            <li className="footer--address-li">
              <a href="tel:514-495-4155" className="footer--icon-href">
                <svg className="footer--phone__icon">
                  <use href={`${solar}#icon-phone`}></use>
                </svg>
                514 495 4155
              </a>
            </li>
            <li className="footer--address-li">
              <a href="https://goo.gl/maps/fxMEmaNNFDm3wXdR7" className="footer--icon-href" target="_blank" rel="noopener noreferrer">
                <svg className="footer--map_icon">
                  <use href={`${solar}#icon-map`}></use>
                </svg>
                659 Ogilvy, Montreal Quebec (H3N-1Z1)
              </a>
            </li>

            <li className="footer--address-li">
              <a href="https://goo.gl/maps/fxMEmaNNFDm3wXdR7" className="footer--icon-href" target="_blank" rel="noopener noreferrer">
                <svg className="footer--direction_icon">
                  <use href={`${solar}#icon-forward`}></use>
                </svg>
                Directions To Here
              </a>
            </li>
          </ul>

          <div>
            <div className="footer--social_container">
              <h2 className="footer--social_title">Social</h2>
              <a href="https://www.facebook.com/sridurkafurniture/" target="_blank" rel="noopener noreferrer">
                <svg className="footer--social_facebook">
                  <use href={`${solar}#icon-facebook2`}></use>
                </svg>
                <p className="footer--facebook_title">Facebook</p>
              </a>
            </div>

            <div className="footer--language">
              <h2 className="footer--language_title">Language</h2>
              <p className="footer--language_paragraph">English</p>
              <p className="footer--language_paragraph">French</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer--disclaimer">
        <div className="mw-1200">
          <h2 className="footer--disclaimer_title">meubles sri durga</h2>
          <p className="footer--disclaimer_paragraph">Copyright &copy; 2020 Meubles Sri Durga, All rights reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
