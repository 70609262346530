import React, { useState, useRef, useEffect } from 'react'
import './sass/solar.scss'

// Components
import Navbar from './react/layout/Navbar'
import Carousel from './react/pages/Carousel'
import Card from './react/components/Card'
import Categories from './react/components/Categories/Categories'
import Location from './react/components/Location'
import Footer from './react/layout/Footer'

const App = () => {
  const categoryNode = useRef()
  const [category, setCategory] = useState('hello')

  const orangeCardNode = useRef()
  const [orange, setOrange] = useState('')
  const greenCardNode = useRef()
  const [green, setGreen] = useState('')
  const blueCardNode = useRef()
  const [blue, setBlue] = useState('')
  const orangeBackButtonNode = useRef('')
  const greenBackButtonNode = useRef('')
  const blueBackButtonNode = useRef('')

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [category, orange, green, blue])

  const handleClick = (e) => {
    console.log('DOM Clicked')
    if (categoryNode.current.contains(e.target)) {
      setCategory('navigation--category_clicked')
    } else if (category === 'navigation--category_clicked') {
      setCategory('')
    }

    if (orangeCardNode.current.contains(e.target)) {
      setOrange(['hover_activated_front', ['hover_activated_back']])
    } else if (orangeBackButtonNode.current.contains(e.target)) {
      setOrange([])
    }

    if (greenCardNode.current.contains(e.target)) {
      setGreen(['hover_activated_front', ['hover_activated_back']])
    } else if (greenBackButtonNode.current.contains(e.target)) {
      setGreen([])
    }

    if (blueCardNode.current.contains(e.target)) {
      setBlue(['hover_activated_front', ['hover_activated_back']])
    } else if (blueBackButtonNode.current.contains(e.target)) {
      setBlue([])
    }
  }

  return (
    <div className="App">
      <Navbar category={category} categoryNode={categoryNode} />
      <Carousel />
      <Card
        orange={orange}
        orangeCardNode={orangeCardNode}
        green={green}
        greenCardNode={greenCardNode}
        blue={blue}
        blueCardNode={blueCardNode}
        orangeBackButtonNode={orangeBackButtonNode}
        greenBackButtonNode={greenBackButtonNode}
        blueBackButtonNode={blueBackButtonNode}
      />
      <Categories />
      <Location />
      <Footer />
    </div>
  )
}

export default App
